/* Global styles */
@import url('https://fonts.googleapis.com/css2?family=Playwrite+GB+S:ital,wght@0,100..400;1,100..400&display=swap');

/* Reset and basic styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif; /* Fallback font */
    line-height: 1.6;
    background-color: #FFFFFF;
}

.container-fluid {
    overflow-x: hidden; /* Hide horizontal overflow */
}

.HomeOverFlow{
    overflow-x: hidden;
}

/* vide0-home-page */

.video-home-page{
    height: 700px;
    overflow: hidden;
    width: 100%;
  
}

.video-home-page{
    padding: 17px 0;
    position: relative;
    top: 0;
}
.video-home-page-video{
    position: absolute;
    top: 0;
    z-index: -1;
}
.video-home-page-text{
    z-index: 1;
    /* padding: 25px; */
    padding-top: 300px;
    /* display: flex; */
    /* justify-content: space-evenly;
    align-items: center; */
    height: 100%;
    
}

.video-home-page-text h1{
    font-weight: bold;
    font-size: 50px;
    color: white;
    font-family: 'Times New Roman', Times, serif;
}





/* Carousel image */
.h-img {
    width: 100%;
    height: 500px;
}

/* Carousel caption */
.h-heading {
    font-family: "Playwrite GB S", cursive;
    font-weight: bold;
    font-style: normal;
    color: #543310;
    font-size: 36px;
}

/* Side image */
.side-img {
    width: 100%;
    height: 420px;
    
}

.left-img{
padding-left: 0 !important;
padding: 50px 0px;
}

/* Section with light background color */
.color-row {
    background-color: #F5EEE6;
    padding: 20px;
}

/* Right bar content */
.right-bar {
    /* margin: 50px;  */
}

.right-bar > h1 {
    font-family: "Playwrite GB S", cursive;
}

.explore-btn-color {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border: none;
    color: #ffffff;
    background-color: #AF6327;
    border-radius: 5px;
    margin-top: 1em;
}

/* Featured facilities section */

/* .facilityOuterDiv{
    position: relative;
    top: 0;

    
}
.facilityOuterDiv2{
    position: absolute;
    top: 0;
    z-index: -1;

}
.facilityOuterDiv1{
    z-index: 1;
    padding: 25px !important;
}
.facilityimg{
    height: 500px;
    width: 100%;
} */


.featured-facilities-section1{
    z-index: 1;
    padding: 25px !important;
}
.featured-facilities-section2{
    position: absolute;
    top: 0;
    z-index: -1;
    opacity: 0.8;
}
.featured-facilities-section2>img{
    height: 600px;
    width: 150%;
}


.featured-facilities-section {
    /* background-color: #F8F9FA; */
    padding: 20px 0;
    position: relative;
    top: 0;
}

.featured-facilities-section .container {
    padding: 0 15px;
}

.featured-facilities-section .fea-head > h1 {
    padding-top: 25px;
    /* font-family: "Playwrite GB S", cursive; */
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
    color: rgb(255, 255, 255)
}

.featured-facilities-section .card {
    border: 1px solid rgba(54, 53, 53, 0.125);
    border-radius: 0.25rem;
    background-color:rgba(0, 0, 0, 0.5);
    padding: 1rem;
    color: white;
}

.featured-facilities-section .card-body {
    flex: 1 1 auto;
}

.featured-facilities-section .feature-icon {
    font-size: 24px;
    color: #FAB702;
}

/* Things to do section */
.things-to-do {
    /* background-color: #F8F9FA; */
    /* padding: 20px; */
    margin-top: 60px ;
}
.things-heading {
    margin-top: 120px ;
}
.things-heading h1{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.things-to-do .things-img {
    width: 100%;
    height: 300px;
    border-radius: 8px;
}

.things-to-do h3{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

/* you can explore more */

.you-can-explore{
    background-color: #AF6327;
    color: white;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

.Indoor{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.indoorGames{
    /* padding: 10px; */
    position: relative;
    overflow: hidden;
}

.indoorGames .indoorImg{
    height: 200px;
    width: 100%;
    border-radius: 5px;
    display: block;
    transition: transform 0.3s ease;
}
.indoorGames .indoorText{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
    text-align: center;
    /* transform: translateY(100%);
    transition: transform 0.3s ease; */
}

/* .indoorGames:hover .indoorText{
    transform: translateY(0);
} */
.indoorGames img{
    /* height: 200px;
    width: 100%;
    border-radius: 5px; */
}

.indoorHeading{
    font-family: cursive;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}







/* Nearby places section */
.near-places > h2 {
    /* background-color: #543310;
    color: #ffffff; */
    padding: 10px;
    background-color: #AF6327;
    color: white;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

/* Individual place image container */
.place-img-container {
    position: relative;
    overflow: hidden;
}

.place-img-container .place-img {
    width: 100%;
    height: 300px;
    display: block;
    transition: transform 0.3s ease;
}

.place-img-container .place-text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px 30px;
    text-align: center;
    /* transform: translateY(100%);
    transition: transform 0.3s ease; */
}

/* .place-img-container:hover .place-text {
    transform: translateY(0);
} */

/* Media queries for responsive design */
@media (max-width: 992px) {
    .home-part-2 .row {
        /* flex-wrap: wrap; */
    }

    .home-part-2 .col-md-6,
    .home-part-2 .col-md-5 {
        /* flex: 0 0 100%; Take full width on smaller screens */
        /* max-width: 100%; */
        /* margin-bottom: 20px; Optional margin between the columns */
    }

    .home-part-2 .col-md-6 {
        /* order: 1; Ensure image comes first */
    }

    .home-part-2 .col-md-5 {
        /* order: 2; Ensure content comes second */
    }
}

/* 
@media (max-width: 768px) {
    .h-img {
        height: 300px;
    }
    .side-img {
    height: 200px !important;
}
    .h-heading {
        font-size: 24px;
    }

    .things-to-do,
    .featured-facilities-section {
       
    }

    .featured-facilities-section .card {
        margin-bottom: 15px;
    }

    .things-to-do .things-img {
        height: 250px;
    }
    
} */

/* ------------------------------------ */

@media screen and (max-width:799px) {
    
    .video-home-page{
        height: 100%;
    }
    .video-home-page video{
        width: 100%;
    }
    .home-welcome{
        padding: 0 !important;
    }
    
    .middle-text{
        margin: 0 !important;
    }
    .guru-krupa-text{
        text-align: start !important;
        padding: 5px 0px;
        margin: 0 !important;
        font-size: 14px;
    }
    .explore-btn{
        margin: 0 !important;
    }
    .explore-btn-color{
        margin: 0 !important;
    }
    .left-img{
        padding: 10px 0px !important;
        margin-bottom: 0 !important;
    }
    .featured-facilities-section2>img{
        height: 1250px;
        width: 100%;
    }
    .thinks-media{
        margin: 0 !important;
    }
    .things-to-do{
        margin: 0 !important;
    }
    .things-heading{
        margin: 0 !important;
    }
    .you-can-explore-main{
        margin: 0 !important;
    }
    .in-out-heading{
        margin: 0 !important;
    }
    .indoorGames{
        width: 100% !important;
        padding: 0 !important;
    }
    .Indoor{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .cont{
        padding: 0 !important;
    }
    .nearby-main{
        margin-top: 5px !important;
    }
    .nearby-sub{
        margin-top: 5px !important;
    }
    
    .video-home-page-text{
        padding:50px 10px;
    }

    .video-home-page-text h1{
        font-size: 20px;
    }
    .things-to-do-cont{
        margin-top: 40px;
    }
    
    
}



