/* Styles for the image section */
.about-img {
    position: relative;
    height: 150px; /* Initial height of the image section */
    margin-bottom: 30px;
}

.about-img img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the entire div */
}

.about-heading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    z-index: 1; /* Ensure heading is above the image */
}

/* --------------------------- */












/* Right bar content */
.right-bar {
    margin: 50px; /* Adjusted margin */
    text-align: center; /* Center align text in the right bar */
}

.right-bar > h1 {
    font-family: "Playwrite GB S", cursive;
}

/* Styles for the side image */
.side-img {
    width: 100%;
    height: 400px; /* Make side image responsive */
    /* max-width: 56%; Adjust maximum width of side image */
}

/* Styles for the card section */
.card-container {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap; /* Allow cards to wrap to the next line */
    gap: 20px; /* Adjust spacing between cards */
    justify-content: space-between; /* Ensure cards are evenly spaced */
}

.this-card {
    flex: 1 0 30%; /* Take 30% of the container width */
    max-width: 30%; /* Maximum width for each card */
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-icon {
    text-align: center;
    margin-bottom: 15px;
}

.card-icon p {
    font-size: 36px; /* Adjust icon size as needed */
    color: #724e2c; /* Adjust icon color */
}

.card-content h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.card-content p {
    font-size: 14px;
    color: #666;
    line-height: 1.6;
}
.hostimage{
    width: 100%;
    height: 300px;
}
.cont-about{
    /* padding: 20px; */
    /* background-color: #f9f9f9; Background color for container */
    /* border: 1px solid #ddd; Border around container */
    /* border-radius: 8px; Rounded corners */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); Box shadow for depth */
}
.hostimage {
    width: 100%;
    height: auto; /* Ensure image is responsive */
    display: block; /* Remove any default spacing */
    border-radius: 8px; /* Rounded corners */
    object-fit: cover; /* Ensure image covers the entire div */
}

/* Host details text */
.host-details {
    /* padding: 0 20px; Padding for text content */
}

.host-details h2 {
    font-size: 24px; /* Host details heading size */
    margin-bottom: 10px; /* Bottom margin for heading */
    /* color: #333; Heading color */
}

.host-details h4 {
    font-size: 20px; /* Subheading size */
    margin-bottom: 15px; /* Bottom margin for subheading */
    /* color: #555; Subheading color */
}

.host-details p {
    font-size: 16px; /* Paragraph text size */
    line-height: 1.6; /* Line height for readability */
    /* color: #777; Paragraph text color */
}

/* ----------------------------- */


.property-details-info{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.property-details-info-room{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}
.property-details-info1{
    display: flex;
    gap: 50px;
}
.property-details-info-room1{
    display: flex;
    gap: 10px;
}
.info-para p{
    text-align: start;
    margin-bottom: 0px ;
}

.info-icon{
    height: 40px;
    width: 40px;
    color: #B3672B;
}











/* Media queries for responsive design */

@media (max-width: 991px) {
    .about-img {
        /* height: 400px; Adjusted height for medium screens */
        /* margin-bottom: 20px; Reduce margin for medium screens */
    }

    .side-img {
        /* height: 450px; */
    }

    .card-container {
        /* flex-direction: row; Ensure cards remain in a row */
        /* justify-content: flex-start; Ensure cards start from the beginning */
        /* margin-top: 20px; Add margin between image section and cards */
    }

    .this-card {
        /* flex: 1 0 calc(30% - 10px); Take 30% of the container width with margin */
        /* max-width: calc(30% - 10px); Maximum width for each card with margin */
        /* margin-bottom: 0; Remove bottom margin to avoid extra space */
    }
}

@media (max-width: 767px) {
    .about-img {
        /* height: 300px; Adjusted height for smaller screens */
    }

    .right-bar {
        /* margin: 20px 0; Adjusted margin for smaller screens */
    }

  
    .host-img,
    .host-details {
        /* width: 100%; Full width on smaller screens */
        /* margin-bottom: 20px; Bottom margin for spacing */
    }

    .host-details h2 {
        /* font-size: 20px; Adjust heading size for smaller screens */
    }

    .host-details h4 {
        /* font-size: 18px; Adjust subheading size for smaller screens */
    }

    .host-details p {
        font-size: 14px; /* Adjust paragraph text size for smaller screens */
    }
}

@media (max-width: 575px) {
    .about-heading h1 {
        /* font-size: 20px; Further reduce font size for smallest screens */
    }

    .about-img {
        /* height: 250px; Adjusted height for smallest screens */
    }

    .this-card {
        /* flex: 1 0 100%; Take full width on smallest screens */
        /* max-width: 100%; Maximum width for each card */
        /* margin-bottom: 20px;/ Add spacing between cards on smallest screens */
    }
}



@media screen and (max-width:799px) {
    .about-img{
        height: 100px;
        margin-bottom: 0 !important;
    }
    .about-head{
        padding: 0px !important;
    }
    .home-part-2{
        margin-top: 0px !important;
    }
    .about-host-img{
        padding: 0px 4px !important;
    }
    .about-host-text{
        padding: 0px 0px !important;
    }
    .host-details{
        padding: 5px;
    }
    .cont-about{
        margin-bottom: 0px !important;
    }
    .about-some-words{
        padding: 0px 15px !important;
    }
    .about-some-words{
        margin: 0 !important;
    }
    .about-some-words-head{
        margin-top: 0 !important;
    }
    .side-img{
        height: 100%;
        width: 100%;
    }
    #side-img-id{
        width: 92%;
    }
    .property-details-info {
        display: flex;
        flex-direction: column;
        align-items: start;
        /* width: 100% !important; */
    }
    .property-details-info-room{
        width: auto !important;
    }
    .card-container{
        display: flex;
        flex-direction: column;
        gap: 0px;
    }
    .this-card{
      max-width: 100% !important; 
       width: 100% !important;
       margin-bottom: 6px;
    }
    .property-details-info1{
        display: flex;
        flex-direction: column;
        gap: 0;
    }
}