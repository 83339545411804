/* General styling for images */
.c-img {
  width: 100%;
  height: 300px;
  /* Default height for smaller screens */
  object-fit: cover;
  /* Ensure image covers the container */
}

.gallery-img {
  position: relative;
  height: 150px;
  /* Initial height of the image section */
  margin-bottom: 10px;
}

.gallery-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensure the image covers the entire div */
}


.g-image {
  width: 100%;
  
  height: 260px;
  /* Default height for smaller screens */
  /* object-fit: cover; */
  /* Ensure image covers the container */
}

.g-para {
  /* background-color: #aa5d23; */
  color: rgb(255, 255, 255);
  padding: 10px;
  /* Adjust padding for better mobile display */
  text-align: center;
  /* Center text */
  /* margin-top: 20px; Add margin for separation */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 1;
}

/* Adjustments for larger screens */
@media (min-width: 992px) {
  .c-img {
    /* height: 550px; */
    /* Increased height for larger screens */
  }

  .g-image {
    /* height: 400px; */
    /* Increased height for larger screens */
  }
}



/* ----------------------------------- */

.gallery-navbar-content {
  /* border: 1px solid #aa5d23; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #AA5D23;
  border-radius: 8px;
  color: black;
  margin: 0px 10px;
  /* box-shadow: 0px 0px 10px #b86323; */
}

.gallery-navbar-para {
  margin-bottom: 0px;
  font-weight: 600;
  border-radius: 6px;
}

.gallery-navbar-para:hover {
  background-color: rgb(29, 26, 26);
  color: rgb(255, 255, 255);
  /* transition-duration: 1s; */
}


@media screen and (max-width:799px) {
  .g-para{
    width: 100%;
    
  }
  .gallery-img{
    margin: 0 !important;
    
  }
  .gallery-navbar-content{
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-top: 6px;
  }
  . {
    margin-top: 10px !important;
  }
}