/* Contact Us page */
.contact-img {
  position: relative;
  height: 150px;
  overflow: hidden;
  margin-bottom: 2px;
  /* Add space after the image */
}

.contact-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensure the image covers the entire div */
}

.contact-heading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 1;
  /* Ensure heading is above the image */
}

#background {
  background-color: #AF8F6F;
  color: #271300;
  padding: 20px;
}

/* .border {
  border: 1px solid #271300;
  border-radius: 10px;
  padding: 20px;
  background-color: transparent; 
}

.border p {
  margin-bottom: 0; 
  word-wrap: break-word; /
} */

@media (max-width: 991px) {
  .contact-heading h1 {
    /* font-size: 30px; */
  }

  .border {
    /* padding: 15px; Adjust padding for tablet screens */
  }
}

@media (max-width: 767px) {
  .contact-heading h1 {
    /* font-size: 24px; */
    /* Adjust font size for smaller screens if needed */
  }

  .contact-img {
    /* height: 300px; */
  }

  .border {
    /* padding: 10px; Further adjust padding for smaller screens */
  }

  .border .fs-5 {
    /* font-size: 14px; Adjust font size for smaller screens */
  }
}

@media (max-width: 575px) {
  .contact-heading h1 {
    /* font-size: 20px; */
  }

  .contact-img {
    /* height: 250px; */
  }

  .border {
    /* padding: 5px; Further adjust padding for smaller screens */
  }

  .border .fs-5 {
    /* font-size: 12px; */
    /* Further reduce font size for smaller screens */
  }
}



/* ------------------------------------ */


.form {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.inputField {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.contactus-button {
  padding: 10px;
  color: white;
  background-color: #AF6327;
  font-weight: 600;
  border: none;
  border-radius: 6px;
}

.btn-danger{

}

.form-detail-ryt {
  text-align: start;
}

.form-address {
  display: flex;
  gap: 10px;
}

.form-address-icon {
  height: 35px;
  width: 35px;
}

.get-in-touch-main {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

}

.get-in-touch-overalldiv {
  background-color: #AF6327;
  color: white;
}

.contactus-numbers p {
  margin-bottom: 3px;
}

.form-icon-div {
  /* width: 20%;
  border-radius: 100%;
  position: relative;
  left: 150px; */
}

@media screen and (max-width:799px) {

  .contactus-overall{
    overflow-x: hidden;
  }

  .contact-img img {
    width: 100%;
    height: 100%;
  }

  .get-in-touch-main {
    display: flex;
    flex-direction: column;
  }

  .get-in-touch-sub {
    width: 100% !important;
    padding: 0px 0px !important;
    margin-bottom: 0px !important;
  }

  .form-icon-div {
    padding: 5px !important;

  }

  .get-in-touch-text {
    padding: 0px !important;

  }

  .get-in-touch-text p {
    margin-bottom: 5px !important;
  }

  .get-in-touch-para {
    padding-left: 0px !important;
  }

  .form-detail-ryt {
    padding: 10px !important;
  }

  .form {
    padding: 0 !important;
  }

  #address-contact{
    padding: 0px 50px !important;
  }
}