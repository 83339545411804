.whatsapp-button {
  border: none;
  padding: 5px 10px; /* Adjust padding as needed */
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; /* Center horizontally */
  color: green; /* Set the color to green */
  background: transparent;
  border-radius: 10%;
}

.whatsapp-button:hover {
  color: darkgreen; /* Darken the color on hover */
}

.whatsapp-icon {
  margin-right: 0px; /* Adjust margin as needed */
  height: 50px;
  width: 50px;
  font-size: 44px; /* Increase the icon size */
  position: relative; /* Ensure relative positioning for fine adjustments */
  top: 2px; /* Adjust vertical position as needed */
}
