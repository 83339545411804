/* ChatWidget.css */

.chat-widget {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 1000; /* Ensure it's above other content */
}
  
.chat-button {
    background-color: green;
    color: #fff;
    border: none;
    height: 100px;
    width: 100px;
    border-radius: 50%; /* Makes the button circular */
    padding: 10px;
    cursor: pointer;
    font-family: Arial, sans-serif;
}
  
.chat-box {
    width: 100%;
    max-width: 400px; /* Adjust maximum width */
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    position: fixed;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%);
    z-index: 1100; /* Ensure it's above other content */
}
  
.chat-header {
    background-color: #f1f1f1;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close-button {
    background: none;
    border: none;
    font-size: 1.2em;
    cursor: pointer;
}
  
.chat-content {
    padding: 10px;
}
  
.input-container {
    margin-bottom: 10px;
    display: flex; /* Ensure items are displayed in a column */
    flex-direction: column; /* Stack items vertically */
}
  
.input-container select,
.input-container input[type="text"],
.input-container input[type="email"],
.input-container textarea {
    width: 100%; /* Full width */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px; /* Adjust font size */
    margin-bottom: 10px; /* Add bottom margin for spacing */
}

.date-input-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Add margin between date inputs */
}

.date-input-container label {
    margin-right: 10px;
}

.date-input-container input[type="date"] {
    flex: 1; /* Take remaining space */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

.date-picker-button {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    width: 100%; /* Full width button */
}


/* Media query for smaller screens */
@media screen and (max-width: 768px) {
    .chat-box {
        width: 90%;
        max-width: 90%;
    }
  
    .input-container select,
    .input-container input[type="text"],
    .input-container input[type="email"],
    .input-container textarea,
    .date-input-container input[type="date"] {
        width: 100%; /* Full width on smaller screens */
        margin-right: 0; /* Remove margin on smaller screens */
    }
}
