.last-part {
    background: linear-gradient(white 40%, #c76418);
    /* color: white; */
    overflow-x: hidden; /* Prevent horizontal overflow */
     /* Padding added for spacing */
}
.loc{
    width: 70%;
    height: 200px;
}
.f-form {
    max-width: 100%; /* Ensure form spans full width on smaller screens */
    padding: 2em;
    background-color:#271300; /* Match background color */
    color: white; /* Match text color */
    box-sizing: border-box; /* Include padding in width calculation */
    margin: 0 auto; /* Center the form horizontally */
}

.form-group .f-label {
    display: block;
    text-align: left;
    color: white; /* Match text color */
    margin-bottom: 8px; /* Adjust spacing */
}

.form-group .f-input,
.form-group .f-text {
    font-size: 1em;
    border: none;
    color: white;
    background-color:#271300; /* Lighter background color */
    border-bottom: 1px solid white; /* Match border color */
    padding: 8px 12px; /* Adjust padding */
    box-sizing: border-box; /* Include padding in width calculation */
    width: 100%; /* Ensure full width */
}

.form-group .f-input:focus,
.form-group .f-text:focus {
    outline: none;
    border-color:#271300; /* Match focus border color */
}

.form-group .f-text {
    height: 100px; /* Adjust height for better mobile experience */
    resize: none;
}

.f-btn {
    padding: 12px 24px;
    margin-top: 10px; /* Adjust spacing */
    border: 1px solid #eff6ef; /* Match border color */
    border-radius: 5px;
    background-color: white;
    color:#271300; /* Match text color */
    font-size: 1em;
    width: 100%;
    cursor: pointer;
}

.f-btn:hover {
    background-color: #eff6ef; /* Hover background color */
}

.error-message {
    color: #ff0000;
    font-size: 14px;
    margin-top: 5px;
}
.anchor-tag-style{
    text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit color from parent */
}
@media (max-width: 768px) {
    .f-form {
        padding: 1em; /* Adjust padding for smaller screens */
    }
}



/* -------------------------------- */

.footer-main{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.quicklinks-ul{
    list-style: none; 
    line-height: 20px; 
}
.quicklinks-ul li{
    padding-left: 70px;
}

.footer-contact{
    margin-bottom:60px;
}

.footer-contact-detail{
    /* padding: 0px 50px 50px 50px;  */
    text-align: start;
    padding-left: 30px;
}
.footer-contact-icon{
    height: 25px;
    width: 25px;
    color: #AE6227;
}
.footer-contact-heading{
    text-align: start;
    padding-left: 40px;
}


@media screen and (max-width:799px) {
    .footer-main {
        display: flex;
        flex-direction: column;
    }
    .footer-sub{
        width: 100% !important;
    }
    .footer-welcome{
        padding: 0 !important;
    }
    .last-part{
        margin: 0 !important;
    }
    .footer-quick{
        padding: 0 !important;
    }
    .quicklinks-ul li{
        padding-left: 0 !important;
    }
    .footer-quick-heading{
        text-align: start ;
    }
    .quicklinks-ul{
        padding: 0 !important;
    }
    .footer-contact{
        width: 100% !important;
        padding: 0 !important;
    }
    .footer-contact-detail{
        padding-left: 0 ;
    }
    .footer-contact-heading{
        padding-left: 0;
    }
    .footer-contact{
        margin-bottom: 0 !important;
    }
}