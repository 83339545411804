.home-icon{
    color:blue;
}
.email-icon{
    color: blue;
 
}
.num-size{
    font-size: 16px;
    color:blue;
}

.headerpara{
  margin-bottom: 0px !important;
  font-size: 14px;
}
.headerpara-hr{
  margin: 0 ;

}

.tail-text{
  color: blue;
}

.b-logo{
    /* height: 100px; */
    width: 200px;
}
.navbar-header .nav-link{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-align: center;
  }
  
  .container-fluid{
    /* padding: 5px 50px; */
  }
  
  .navbar-left-content {
    display: flex;
  }
  
  .navbar-right-content {
    display: flex;
    /* padding: 5px 0; */
  }
  
  .nav-logo-mbl {
    display: none;
  }
  
  .headers .nav-link {
    margin: 0px 10px;
    color:white;
    font-size: 14px;
    font-weight: bold;
  }
  
  @media screen and (max-width: 799px) {
   
    .nav-logo-mbl {
      width: 100px;
      height: 40px;
      display: flex;
    }
   
    .container-fluid {
      /* padding: 5px 12px; */
  }
    .header-mail{
      padding-top: 10px !important;
    }
    .navbar{
      display: flex;
      flex-direction: column !important;
      width: 100% !important;
      background-color: white;
      /* font-family: 'Times New Roman', Times, serif; */
      font-weight: 600;
      /* border-radius: 5px; */
    }
    .header-row{
      display: flex;
      flex-direction: column;
      align-items: start !important;
    }
    .numberdiv{
      padding-top: 5px !important;
    }
  }